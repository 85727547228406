<template>
  <v-dialog
    v-model="dialog"
    max-width="732"
  >
    <v-card outlined>
      <v-card-title class="body-2 font-weight-bold">
        {{ $_strings.orderCreate.MULTIPICK_LOC }}
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-form ref="multipickLoc" lazy-validation @submit.prevent="setLocMultipick">
        <v-card-text>
          <v-container class="pa-0">
            <v-select
              ref="itemMultipickLoc"
              v-model="locationId"
              :items="items"
              :placeholder=$_strings.orderCreate.MULTIPICK_TITLE
              class="body-2 mt-5"
              outlined
              color="primary"
              prepend-inner-icon="mdi-map-marker"
            ></v-select>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-0" justify="end">
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                outlined
                block
                class="mb-2 mr-2"
                @click="dialog = false"
              >
                {{$_strings.common.BACK}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                block
                type="submit"
                :disabled="!locationId"
              >
                {{$_strings.common.SAVE}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    itemsMultipick: {
      type: Array,
      default: () => [],
    },
    shipmentsDetail: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      locationId: '',
      dialog: false,
    };
  },
  computed: {
    items() {
      if (this.shipmentsDetail.length > 0) {
        const multipickData = this.itemsMultipick.filter((itemMasterMultipick) => itemMasterMultipick.value !== this.shipmentsDetailMultipick.filter((itemMultipick) => itemMultipick.locationId === itemMasterMultipick.value)[0]?.locationId);
        return multipickData;
      }
      return this.itemsMultipick;
    },
    shipmentsDetailMultipick() {
      const shipmentMultipick = [];
      this.shipmentsDetail.forEach((element, index) => {
        if (element.multiType === 'MULTIPICK') {
          shipmentMultipick.push(this.shipmentsDetail[index]);
        }
      });
      return shipmentMultipick;
    },
  },
  methods: {
    setLocMultipick() {
      const checkDuplicateShipmentDetail = this.shipmentsDetail.find((shipment) => shipment.locationId === this.locationId);
      if (checkDuplicateShipmentDetail) {
        this.$dialog.notify.error('Tidak bisa menambahkan multipick atau multidrop di lokasi yang sama');
        return;
      }

      const sequenceNo = 1 + this.shipmentsDetail.filter((element) => element.multiType === 'MULTIPICK').length;

      const tempMultipick = {
        sequenceNo,
        pickupDropType: 'PICKUP',
        locationId: this.locationId,
        multiType: 'MULTIPICK',
        picName: '',
        picPhone: '',
        picNote: '',
      };

      if (this.isEdit) {
        const index = this.shipmentsDetail.indexOf(this.selectedItem);
        tempMultipick.sequenceNo = this.shipmentsDetail[index].sequenceNo;
        this.shipmentsDetail.splice(index, 1, tempMultipick);
        this.$parent.isEdit = false;
        this.$parent.selectedItem = {};
      } else {
        this.shipmentsDetail.push(tempMultipick);
      }
      this.locationId = '';
      this.dialog = false;
    },
  },
};
</script>
