<template>
  <v-form  ref="createOrder" lazy-validation @submit.prevent="createOrder">
    <v-container fluid>

      <!-- ORIGIN -->
      <v-row>
        <v-col>
          <v-card ref="cardLocOrigin">
            <v-container fluid>
              <v-row justify="space-between">
                <v-col>
                  <h4>{{ $_strings.orderCreate.ORIGIN_LOC }}</h4>
                  <div class="d-flex">
                    <div>
                      <v-icon class="mr-2 ml-4 icon-origin">
                        mdi-map-marker
                      </v-icon>
                    </div>
                    <div class="d-inline">
                      <span>
                        {{ form.backhauling.originLocationId && form.items.itemsOriginBackhauling.length && form.items.itemsOriginBackhauling.find((origin) => origin.value === form.backhauling.originLocationId).text || $_strings.orderCreate.ORIGIN_TITLE }}
                      </span>
                      <br/>
                      <span class="text-silverChalice">
                        {{form.backhauling.originLocationId && form.items.itemsOriginBackhauling.length && form.items.itemsOriginBackhauling.find((origin) => origin.value === form.backhauling.originLocationId).originLocationAddress || '-'}}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col class="text-right">
                  <v-tooltip v-if="loaders.isLoadingGetOriginBackhauling" top v-model="showTooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-progress-circular
                        class="mt-3"
                        v-bind="attrs"
                        v-on="on"
                        indeterminate
                        color="primary"
                        size="25"
                      ></v-progress-circular>
                    </template>
                    <span>{{$_strings.orderCreate.LOAD_MESSAGE.LOCATION}}</span>
                  </v-tooltip>
                  <v-icon
                    v-else-if="errors.isErrorGetOriginBackhauling"
                    color="primary"
                    class="mt-3"
                    @click="$emit('getRouteOriginBackhauling')"
                  >
                    mdi-reload
                  </v-icon>
                  <v-icon
                    v-if="form.items.itemsOriginBackhauling.length"
                    color="primary"
                    class="mt-3"
                    @click="$refs.dialogOrigin.dialog = true"
                  >
                    mdi-pencil
                  </v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-textarea
                    :placeholder=$_strings.orderCreate.ADD_INFO
                    no-resize
                    outlined
                    v-model="form.backhauling.originNote"
                    rows="5"
                    :counter="255"
                    :rules="[(v) => rulesNotes(v, false, 255, $_strings.orderCreate.ADD_INFO)]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column">
                <v-col class="pt-0">
                  <span class="grey--text body">{{ $_strings.orderCreate.ORIGIN_CONTACT }}</span>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-text-field
                    :placeholder=$_strings.orderCreate.ORIGIN_NAME
                    class="body-2"
                    outlined
                    v-model="form.backhauling.originSenderName"
                    prepend-inner-icon="mdi-account-circle"
                  ></v-text-field>
                  <phone-number-format
                    :placeholder=$_strings.orderCreate.ORIGIN_PHONE
                    bindClass="body-2"
                    outlined
                    v-model="form.backhauling.originSenderPhone"
                    prepend-inner-icon="mdi-phone"
                    :required="false"
                  ></phone-number-format>
                </v-col>
                <v-col class="pt-0 pb-0 mb-4">
                  <Multipick
                    v-if="form.serviceType !== 'LCL' && form.items.itemsMultipickBackhauling.length"
                    :shipmentsDetail="form.backhauling.shipmentsDetail"
                    :itemsMultipick="form.items.itemsMultipickBackhauling"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <span ref="errorLocOrigin" class="caption red--text"></span>
        </v-col>
      </v-row>

      <!-- DESTINATION -->
      <v-row>
        <v-col>
          <v-card ref="cardLocDest">
            <v-container fluid>
              <v-row justify="space-between">
                <v-col>
                  <h4>{{ $_strings.orderCreate.DESTINATION_TITLE }}</h4>
                  <div class="d-flex">
                    <div>
                      <v-icon class="mr-2 ml-4 icon-destination">
                        mdi-map-marker
                      </v-icon>
                    </div>
                    <div class="d-inline">
                      <span>
                        {{ form.backhauling.destinationLocationId && form.items.itemsDestinationBackhauling.length && form.items.itemsDestinationBackhauling.find((destination) => destination.value === form.backhauling.destinationLocationId).text || $_strings.orderCreate.ORIGIN_TITLE }}
                      </span>
                      <br/>
                      <span class="text-silverChalice">{{
                        form.backhauling.destinationLocationId && form.items.itemsDestinationBackhauling.length && form.items.itemsDestinationBackhauling.find((destination) => destination.value === form.backhauling.destinationLocationId).destinationLocationAddress || '-'}}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col class="text-right" v-if="form.items.itemsDestinationBackhauling.length">
                  <v-icon color="primary" class="mt-3" @click="$refs.dialogDestination.dialog = true" >
                    mdi-pencil
                  </v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-textarea
                    :placeholder=$_strings.orderCreate.ADD_INFO
                    no-resize
                    v-model="form.backhauling.destinationNote"
                    outlined
                    rows="5"
                    :counter="255"
                    :rules="[(v) => rulesNotes(v, false, 255, $_strings.orderCreate.ADD_INFO)]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column">
                <v-col class="pt-0">
                  <span class="grey--text body">{{ $_strings.orderCreate.DESTINATION_CONTACT }}</span>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-text-field
                    :placeholder=$_strings.orderCreate.DESTINATION_NAME
                    class="body-2"
                    v-model="form.backhauling.destinationReceiverName"
                    outlined
                    prepend-inner-icon="mdi-account-circle"
                  ></v-text-field>
                  <phone-number-format
                    :placeholder=$_strings.orderCreate.DESTINATION_PHONE
                    bindClass="body-2"
                    outlined
                    v-model="form.backhauling.destinationReceiverPhone"
                    prepend-inner-icon="mdi-phone"
                    :required="false"
                  ></phone-number-format>
                </v-col>
                <v-col class="pt-0 pb-0 mb-4">
                  <Multidrop
                    v-if="form.serviceType !== 'LCL' && form.items.itemsMultidropBackhauling.length"
                    :shipmentsDetail="form.backhauling.shipmentsDetail"
                    :itemsMultidrop="form.items.itemsMultidropBackhauling"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <span ref="errorLocDest" class="caption red--text"></span>
        </v-col>
      </v-row>

      <!-- GOODS -->
      <Goods
        :form="form.backhauling"
        :itemsCategory="form.items.itemsCategory"
        :itemsSubCategory="form.items.itemsSubCategoryBackhauling"
        :filterCategory="filters.category"
        :filterSubCategory="subCategoryFilter"
        @updateSubCategoryItems="updateSubCategoryItems"
        @resetSubCategoryItems="resetSubCategoryItems"
        @updateCategoryItems="(items) => $emit('updateCategoryItems', items)"
      />

      <!-- NOTES -->
      <v-row>
        <v-col>
          <v-card>
            <v-container fluid>
              <v-row class="d-flex flex-column mt-2">
                <v-col class="pt-0">
                  <h4>{{ $_strings.orderCreate.ADD_NOTE }}</h4>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-textarea
                    :placeholder="$_strings.orderCreate.ADD_INFO"
                    no-resize
                    v-model="form.backhauling.additionalNote"
                    outlined
                    rows="5"
                    :counter="255"
                    :rules="[(v) => rulesNotes(v, false, 255, $_strings.orderCreate.ADD_INFO)]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <DialogOrigin
        ref="dialogOrigin"
        v-bind="$props"
        :isBackhauling="true"
      />
      <DialogDestination
        ref="dialogDestination"
        v-bind="$props"
        :isBackhauling="true"
      />
    </v-container>
  </v-form>
</template>

<script>
import DialogOrigin from '../Dialog/Origin.vue';
import DialogDestination from '../Dialog/Destination.vue';
import Goods from './Goods';
import Multipick from './Multipick';
import Multidrop from './Multidrop';
import { rulesNotes } from '../../../helper/commonHelpers';

export default {
  name: 'check-order',
  components: {
    DialogOrigin,
    DialogDestination,
    Goods,
    Multipick,
    Multidrop,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    loaders: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTooltip: true,
      menu_date: false,
      menu_clock: false,
      rules: {
        required: (v, text, isPhone = false) => {
          if (!v) {
            return this.$_strings.messages.validation.REQUIRED(text);
          }
          if (v.length > 255) {
            return 'Maksimal 225 karakter atau kurang';
          }
          return !!v;
        },
        dateRules: [
          (v) => !!v || 'Tanggal harus diisi',
        ],
        time: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Jam'),
          (value) => {
            const pattern = /(?:[01]\d|2[0-3]):(?:[0-5]\d)/;
            return pattern.test(value) || 'Format jam salah';
          },
        ],
        timeRules: [
          (v) => !!v || 'Jam harus diisi',
        ],
        category: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Kategori')],
        subCategory: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Sub Kategori')],
      },
      isLoadingGetSubCategory: false,
      isValidForm: true,
      subCategoryFilter: {
        page: 0,
        size: 50,
        totalData: 0,
      },
    };
  },
  computed: {
    getNewDate() {
      const currentDate = new Date().toISOString();
      return currentDate;
    },
  },
  methods: {
    rulesNotes,
    resetSubCategoryItems() {
      this.subCategoryFilter = {
        page: 0,
        size: 50,
        totalData: 0,
      };
      this.form.backhauling.subCategoryId = null;
      this.form.items.itemsSubCategoryBackhauling = [];
    },
    updateSubCategoryItems(items) {
      this.form.items.itemsSubCategoryBackhauling = [...this.form.items.itemsSubCategoryBackhauling, ...items];
    },
    resetForm() {
      // RESET FORM
      this.$refs.createOrder.reset();

      // REMOVE BORDER AND MESSAGE ERROR ORIGN
      this.$refs.cardLocOrigin.$el.classList.remove('v-card-error');
      this.$refs.errorLocOrigin.innerHTML = '';
      this.$refs.errorLocOrigin.classList.remove('v-messages', 'error--text');

      // REMOVE BORDER AND MESSAGE ERROR DESTINATION
      this.$refs.cardLocDest.$el.classList.remove('v-card-error');
      this.$refs.errorLocDest.innerHTML = '';
      this.$refs.errorLocDest.classList.remove('v-messages', 'error--text');
    },
    validateForm() {
      let validateLocOrigin = true;
      let validateLocDest = true;
      if (!this.form.backhauling.originLocationId) {
        this.$refs.cardLocOrigin.$el.classList.add('v-card-error');
        this.$refs.errorLocOrigin.innerHTML = 'Lokasi asal wajib diisi';
        this.$refs.errorLocOrigin.classList.add('v-messages', 'error--text');
        validateLocOrigin = false;
      }
      if (!this.form.backhauling.destinationLocationId) {
        this.$refs.cardLocDest.$el.classList.add('v-card-error');
        this.$refs.errorLocDest.innerHTML = 'Lokasi tujuan akhir wajib diisi';
        this.$refs.errorLocDest.classList.add('v-messages', 'error--text');
        validateLocDest = false;
      }

      if (this.$refs.createOrder.validate() && validateLocOrigin && validateLocDest) {
        this.isValidForm = true;
        return true;
      }

      this.isValidForm = false;
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
  .v-card {
    border: 1px solid rgb(221, 221, 221);
    margin: 0;
    box-shadow: none !important;
  }
  .v-icon, .v-btn__content .v-icon {
    color: #44d1e0;
  }
  .v-icon.icon-destination {
    color: rgb(221, 24, 24);
  }
  .v-icon.mdi-close {
    color: rgb(63, 59, 59);
    position: absolute;
    right: 0;
    top: -2px;
  }
  .v-card-border-blue {
    border-color: rgb(52, 52, 185);
  }
  .v-card-error {
    border:1px solid rgb(255, 0, 0);
  }
</style>
