<template>
  <v-dialog
    v-model="dialog"
    max-width="732"
  >
    <v-card outlined>
      <v-card-title class="body-2 font-weight-bold">
        {{ $_strings.orderCreate.DESTINATION_TITLE }}
        <v-spacer></v-spacer>
        <v-icon color="red" @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-form lazy-validation @submit.prevent="setDestination">
        <v-card-text>
          <v-container class="pa-0">
            <v-autocomplete
              v-if="isBackhauling"
              :items="form.items.itemsDestinationBackhauling"
              :placeholder=$_strings.orderCreate.DESTINATION_LOC
              v-model="destinationLocationId"
              class="body-2 mt-5"
              outlined
              color="primary"
              prepend-inner-icon="mdi-map-marker"
            >
            </v-autocomplete>
            <common-auto-complete-items
              v-else
              :optional="{
                serviceType: form.serviceType,
                transportTypeId: filter.transportTypeId,
                originId: filter.originId,
                mouId
              }"
              type="mouDestinationRoute"
              searchName="destinationName"
              outlined
              clearable
              item-value="destinationLocationId"
              item-text="destinationLocationName"
              class="body-2 mt-5"
              prepend-inner-icon="mdi-map-marker"
              v-model="destinationLocationId"
              hide-details
              :placeholder=$_strings.orderCreate.DESTINATION_LOC
              :filter="filter"
              :items="form.items.itemsDestination"
              @updateItems="updateItems"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-0" justify="end">
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                outlined
                block
                class="mb-2 mr-2"
                @click="dialog = false"
              >
                {{$_strings.common.BACK}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                block
                type="submit"
                :disabled="!destinationLocationId"
              >
                {{$_strings.common.SAVE}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

let setSearch;

export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    isBackhauling: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dialog: false,
      destinationLocationId: '',
      oldFilter: {
        page: 0,
        totalData: 0,
      },
    };
  },
  computed: {
    mouId() {
      const { itemsOrigin } = this.form.items;
      let mouId = null;
      if (itemsOrigin.find((x) => x.mouId)) {
        mouId = itemsOrigin.find((x) => x.mouId).mouId;
      }
      return mouId;
    },
  },
  methods: {
    updateItems(items) {
      const newLocDestination = [];
      items.forEach((route) => {
        newLocDestination.push({
          ...route,
          id: route.id,
          text: route.destinationLocationName,
          value: route.destinationLocationId,
          destinationLocationCityId: route.destinationLocationCityId,
          destinationLocationCityName: route.destinationLocationCityName,
          destinationLocationAddress: route.destinationLocationAddress,
          destinationLocationProvinceId: route.destinationLocationProvinceId,
        });
      });
      this.form.items.itemsDestination = [...this.form.items.itemsDestination, ...newLocDestination];
    },
    setDestination() {
      this.dialog = false;
      if (this.isBackhauling) {
        this.form.backhauling.destinationLocationId = this.destinationLocationId;
        // FIND FOR GET ID DESTINATION FOR SET MOU ROUTES ID
        const destinationBackhauling = this.form.items.itemsDestinationBackhauling.find((res) => res.value === this.destinationLocationId);
        this.form.backhauling.mouRoutesId = destinationBackhauling.id;
      } else {
        this.form.destinationLocationId = this.destinationLocationId;

        // FIND FOR GET ID DESTINATION
        const destination = this.form.items.itemsDestination.find((res) => res.value === this.destinationLocationId);
        this.form.mouRoutesId = destination.id;
        this.$parent.$parent.$emit('resetValueData', 'locDest');
        this.$parent.$parent.$emit('getRouteOriginBackhauling');
        this.$parent.$parent.$emit('getMultishipper');
      }
      this.$parent.$parent.$emit('getMultipick');
      this.$parent.$parent.$emit('getMultidrop');

      this.destinationLocationId = '';
      // REMOVE BORDER AND MESSAGE ERROR DESTINATION
      this.$parent.$parent.$refs.cardLocDest.$el.classList.remove('v-card-error');
      this.$parent.$parent.$refs.errorLocDest.innerHTML = '';
    },
  },
};
</script>
