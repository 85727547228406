<template>
  <v-row>
    <v-col>
      <v-card>
        <v-container fluid>
          <v-row class="d-flex flex-column mt-2">
            <v-col class="pt-0">
              <h4>{{ $_strings.orderCreate.GOODS }}</h4>
            </v-col>
            <v-col class="pt-0 pb-0">
              <common-auto-complete-items
                type="categoryList"
                searchName="name"
                item-value="id"
                item-text="name"
                class="body-2"
                prepend-inner-icon="mdi-package-variant-closed"
                outlined
                v-model="form.categoryId"
                :disabled="disabled"
                :placeholder="$_strings.orderCreate.GOODS_CATEGORY"
                :rules="rules.category"
                :filter="filterCategory"
                :items="itemsCategory"
                @updateItems="(items) => $emit('updateCategoryItems', items)"
                @change="$emit('resetSubCategoryItems')"
              />
              <common-auto-complete-items
                :type="form.categoryId ? 'subCategoryList' : null"
                searchName="name"
                item-value="id"
                item-text="name"
                class="body-2"
                prepend-inner-icon="mdi-package-variant-closed"
                outlined
                :optional="{
                  categoryId: form.categoryId
                }"
                v-model="form.subCategoryId"
                :disabled="disabled"
                :placeholder="$_strings.orderCreate.GOODS_SUB_CATEGORY"
                :items="itemsSubCategory"
                :rules="rules.subCategory"
                :filter="filterSubCategory"
                @updateItems="(items) => $emit('updateSubCategoryItems', items)"
              />
            </v-col>
            <v-col class="pt-0 pb-0">
              <v-textarea
                no-resize
                v-model="form.goodsNote"
                outlined
                rows="5"
                :placeholder=$_strings.orderCreate.ADD_INFO
                :counter="255"
                :rules="[(v) => rulesNotes(v, false, 255, $_strings.orderCreate.ADD_INFO)]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { rulesNotes } from '../../../helper/commonHelpers';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    filterCategory: {
      type: Object,
      default: () => {},
    },
    filterSubCategory: {
      type: Object,
      default: () => {},
    },
    itemsCategory: {
      type: Array,
      default: () => [],
    },
    itemsSubCategory: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        category: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Kategori')],
        subCategory: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Sub Kategori')],
      },
    };
  },
  methods: {
    rulesNotes,
  },
};
</script>

<style scoped lang="scss">
  .v-card {
    border: 1px solid rgb(221, 221, 221);
    margin: 0;
    box-shadow: none !important;
  }
</style>
