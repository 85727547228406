<template>
  <v-dialog
    v-model="dialog"
    max-width="732"
  >
    <v-card outlined>
      <v-card-title class="body-2 font-weight-bold">
        {{ $_strings.orderCreate.MULTIDROP_LOC }}
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-form ref="multipickLoc" lazy-validation @submit.prevent="setLocMultidrop">
        <v-card-text>
          <v-container class="pa-0">
            <v-select
              v-model="locationId"
              :items="items"
              :placeholder=$_strings.orderCreate.MULTIDROP_TITLE
              class="body-2 mt-5"
              outlined
              color="primary"
              prepend-inner-icon="mdi-map-marker"
            ></v-select>
          </v-container>
        </v-card-text>
        <v-card-actions >

          <v-row class="ma-0 pa-0" justify="end">
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                outlined
                block
                class="mb-2 mr-2"
                @click="dialog = false"
              >
                {{$_strings.common.BACK}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                color="primary"
                block
                type="submit"
                :disabled="!locationId"
              >
                {{$_strings.common.SAVE}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    itemsMultidrop: {
      type: Array,
      default: () => [],
    },
    shipmentsDetail: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      locationId: '',
      dialog: false,
    };
  },
  computed: {
    items() {
      if (this.shipmentsDetail.length > 0) {
        const multipickData = this.itemsMultidrop.filter((itemMasterMultidrop) => itemMasterMultidrop.value !== this.shipmentsDetailMultidrop.filter((itemMultidrop) => itemMultidrop.locationId === itemMasterMultidrop.value)[0]?.locationId);
        return multipickData;
      }
      return this.itemsMultidrop;
    },
    shipmentsDetailMultidrop() {
      const shipmentMultidrop = [];
      this.shipmentsDetail.forEach((element, index) => {
        if (element.multiType === 'MULTIDROP') {
          shipmentMultidrop.push(this.shipmentsDetail[index]);
        }
      });
      return shipmentMultidrop;
    },
  },
  methods: {
    setLocMultidrop() {
      const checkDuplicateShipmentDetail = this.shipmentsDetail.find((shipment) => shipment.locationId === this.locationId);
      if (checkDuplicateShipmentDetail) {
        this.$dialog.notify.error('Tidak bisa menambahkan multipick atau multidrop di lokasi yang sama');
        return;
      }

      const sequenceNo = 1 + this.shipmentsDetail.filter((element) => element.multiType === 'MULTIDROP').length;

      const tempMultidrop = {
        sequenceNo,
        pickupDropType: 'DROP',
        locationId: this.locationId,
        multiType: 'MULTIDROP',
        picName: '',
        picPhone: '',
        picNote: '',
      };

      if (this.isEdit) {
        const index = this.shipmentsDetail.indexOf(this.selectedItem);
        tempMultidrop.sequenceNo = this.shipmentsDetail[index].sequenceNo;
        this.shipmentsDetail.splice(index, 1, tempMultidrop);
        this.$parent.isEdit = false;
        this.$parent.selectedItem = {};
      } else {
        this.shipmentsDetail.push(tempMultidrop);
      }
      this.locationId = '';
      this.dialog = false;
    },
  },
};
</script>
