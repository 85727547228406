<template>
  <div>
    <v-card-actions class="pa-0">
      <div
      >
        <h4>{{$_strings.orderCreate.MULTIPICK}}</h4>
      </div>
      <v-spacer></v-spacer>
      <v-icon color="primary" @click="$refs.dialogMultipick.dialog = true">
        mdi-plus-circle
      </v-icon>
      <v-btn
        v-if="shipmentsDetail && shipmentsDetail.find((shipment) => shipment.multiType === 'MULTIPICK')"
        icon
        @click="showMultipick = !showMultipick"
      >
        <v-icon color="primary">{{ showMultipick ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-if="shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK').length && itemsMultipick.length"  v-show="showMultipick">
        <v-card-text v-for="(item, index) in shipmentsDetail.filter((shipment) => shipment.multiType === 'MULTIPICK')" :key="index">
          <v-row>
            <v-col class="pl-0 mb-5">
              <div class="d-flex">
                <div class="mr-2 counterMultipick d-flex align-center">
                  <span class="font-weight-bold">{{item.sequenceNo}}</span>
                </div>
                <div class="d-flex">
                  <div>
                    <v-icon color="green" class="mr-2 icon-origin">
                      mdi-map-marker
                    </v-icon>
                  </div>
                  <div class="d-inline">
                    <span>
                      {{itemsMultipick.find((multipick) => multipick.value === item.locationId).locationName}}
                    </span>
                    <br/>
                    <span class="text-silverChalice">
                      {{itemsMultipick.find((multipick) => multipick.value === item.locationId).locationCityName}}
                    </span>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col class="pl-0 pr-0 mb-5">
              <div class="d-flex justify-end">
                <div class="d-flex">
                  <div>
                    <v-icon
                      @click="showDialogDeleteMultipick(item)"
                      color="red"
                      class="mr-2 icon-origin">
                      mdi-delete
                    </v-icon>
                  </div>
                  <div>
                    <v-icon
                      @click="editMultipick(item)"
                      color="primary">
                      mdi-pencil
                    </v-icon>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0">
              <v-textarea
                :placeholder=$_strings.orderCreate.ADD_INFO
                no-resize
                outlined
                v-model="item.picNote"
                rows="5"
                :counter="255"
                :rules="[(v) => rulesNotes(v, false, 255, $_strings.orderCreate.ADD_INFO)]"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-col class="pl-0">
              <span class="grey--text body">{{ $_strings.orderCreate.ORIGIN_CONTACT }}</span>
            </v-col>
            <v-col class="pa-0">
              <v-text-field
                :placeholder=$_strings.orderCreate.ORIGIN_NAME
                class="body-2 mb-2"
                outlined
                v-model="item.picName"
                hide-details
                prepend-inner-icon="mdi-account-circle"
              ></v-text-field>
              <phone-number-format
                :placeholder=$_strings.orderCreate.ORIGIN_PHONE
                bindClass="body-2 mb-4"
                outlined
                v-model="item.picPhone"
                prepend-inner-icon="mdi-phone"
                hide-details
                :required="false"
              ></phone-number-format>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- DIALOG DELETE MULTIPICK-->
        <v-dialog
          v-model="dialogDeleteMultipick"
          transition="dialog-top-transition"
          max-width="400"
        >
          <template v-slot:default="dialog">
            <v-card>
              <v-card-text>
                <div class="pt-2">Apakah anda akan menghapus
                  "{{ selectedItem.locationId && itemsMultipick.find((multipick) => multipick.value === selectedItem.locationId).text}}" dari Multipick ?</div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  text
                  @click="dialog.value = false"
                >Tidak</v-btn>
                <v-btn
                  text
                  @click="deleteMultipick"
                >Ya</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-expand-transition>
    <DialogMultipick
      ref="dialogMultipick"
      v-bind="$props"
      :isEdit="isEdit"
      :selectedItem="selectedItem"
    />
  </div>
</template>

<script>
import DialogMultipick from '../Dialog/Multipick.vue';
import { rulesNotes } from '../../../helper/commonHelpers';

export default {
  name: 'multipick',
  props: {
    shipmentsDetail: {
      type: Array,
      default: () => [],
    },
    itemsMultipick: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DialogMultipick,
  },
  data() {
    return {
      showMultipick: true,
      dialogDeleteMultipick: false,
      isEdit: false,
      selectedItem: {},
      rules: {
        multipickLoc: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Lokasi Multipick')],
      },
    };
  },
  methods: {
    rulesNotes,
    editMultipick(item) {
      this.selectedItem = item;
      this.isEdit = true;
      this.$refs.dialogMultipick.dialog = true;
    },
    showDialogDeleteMultipick(item) {
      this.selectedItem = item;
      this.dialogDeleteMultipick = true;
    },
    deleteMultipick() {
      const { locationId } = this.selectedItem;
      this.dialogDeleteMultipick = false;
      const findIndexDeleted = this.shipmentsDetail.findIndex((res) => res.locationId === locationId);
      this.shipmentsDetail.splice(findIndexDeleted, 1);

      // ORDERED SEQUENCE NO MULTIPICK
      let sequenceNo = 1;
      this.shipmentsDetail.forEach((shipment, index) => {
        if (shipment.multiType === 'MULTIPICK') {
          this.shipmentsDetail[index].sequenceNo = sequenceNo;
          sequenceNo += 1;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .v-icon, .v-btn__content .v-icon {
    color: #44d1e0;
  }
  .counterMultipick {
    background: #ddd;
    padding: 5px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-icon.mdi-close {
    color: rgb(63, 59, 59);
    position: absolute;
    right: 0;
    top: -2px;
  }
</style>
